import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import gsap from 'gsap';
import { Color } from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

// Debug
const gui = new dat.GUI()
gui.hide()

/* 
Loaders
*/
const gltfLoader = new GLTFLoader()
const cubeTextureLoader = new THREE.CubeTextureLoader()
const textureLoader = new THREE.TextureLoader()

// Text Animations
gsap.from('canvas', { duration: 7, y: -400, opacity: 0, ease: 'back' })
gsap.to('canvas', {delay: 4, duration: 2, y: -1, repeat: -1, yoyo: true, ease: 'sine.inOut'})
gsap.fromTo('#bg-text', {opacity: 0}, {delay: 6, opacity: 1, duration: 4})

/**
 * Base
 */

// Canvas
const canvas = document.querySelector('.webgl')

// Scene
const scene = new THREE.Scene()

/* 
Model(s)
*/
gltfLoader.load(
    '/models/Logo3D/VNVStudioLogoSoon.gltf',
    (gltf) => {
        gltf.scene.scale.set(2.5, 2.5, 2.5)
        gltf.scene.position.set(0, -3.2, 0)
        gltf.scene.rotation.y = -Math.PI * 0.65
        gltf.scene.children[0].material.roughness = 0.25
        scene.add(gltf.scene)
        gsap.to(gltf.scene.rotation, { delay: 5, duration: 2, y: -Math.PI * 0.1, opacity: 0, ease: 'back' })
        console.log(gltf);


        gui.add(gltf.scene.rotation, 'y',
         -Math.PI,
          Math.PI, 0.001).name('modelRotation')
          updateAllMaterials()
    }
)

// const rayCaster = new THREE.Raycaster(THREE.Vector3(5,3,1), THREE.Vector3(0, 0, 0), 3, 5)

/* 
Lights
*/
const pointLight = new THREE.PointLight('orange', 9)
pointLight.position.set(-4, 10, 2)

const pointLight02 = new THREE.PointLight('blue', 7)
pointLight02.position.set(0.2, 2.6, -3.2)

const spotLight = new THREE.SpotLight('orange', 4)
spotLight.penumbra = 0.3
spotLight.position.set(0, 7, 4)

// Animate light position
gsap.to(pointLight.position, {delay: 5, duration: 3, x: 5, repeat: -1, yoyo: true, ease: 'sine.inOut'})
scene.add(pointLight, pointLight02, spotLight)

const directionalLight = new THREE.DirectionalLight('blue', 45)
directionalLight.position.set(5, -1.8, -0.9)
directionalLight.castShadow = true
directionalLight.shadow.camera.far = 10
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.normalBias = 0.02
scene.add(directionalLight)

// const directionalLightCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera)
// scene.add(directionalLightCameraHelper)

// const directLightFolder = gui.addFolder('Direct Light')
// const pointLightFolder = gui.addFolder('Point Light')
// const pointLightFolder02 = gui.addFolder('Point Light02')
// const spotLightFolder = gui.addFolder('Spot Light')

// directLightFolder.add(directionalLight, 'intensity', 0, 45, 0.01).name('dirLightIntensity')
// directLightFolder.add(directionalLight.position, 'x', -5, 5, 0.001).name('dirLightX')
// directLightFolder.add(directionalLight.position, 'y', -5, 10, 0.001).name('dirLightY')
// directLightFolder.add(directionalLight.position, 'z', -5, 5, 0.001).name('lightZ')

// pointLightFolder.add(pointLight, 'intensity', 0, 45, 0.01).name('PointLightIntensity')
// pointLightFolder.add(pointLight.position, 'x', -5, 5, 0.001).name('PLightX')
// pointLightFolder.add(pointLight.position, 'y', -5, 10, 0.001).name('PLightY')
// pointLightFolder.add(pointLight.position, 'z', -5, 5, 0.001).name('PLightZ')

// pointLightFolder02.add(pointLight02, 'intensity', 0, 45, 0.01).name('PointLightIntensity')
// pointLightFolder02.add(pointLight02.position, 'x', -5, 5, 0.001).name('PLightX')
// pointLightFolder02.add(pointLight02.position, 'y', -5, 10, 0.001).name('PLightY')
// pointLightFolder02.add(pointLight02.position, 'z', -5, 5, 0.001).name('PLightZ')

// spotLightFolder.add(spotLight, 'intensity', 0, 45, 0.01).name('SpotLightIntensity')
// spotLightFolder.add(spotLight.position, 'x', -5, 5, 0.001).name('SLightX')
// spotLightFolder.add(spotLight.position, 'y', -5, 10, 0.001).name('SLightY')
// spotLightFolder.add(spotLight.position, 'z', -10, 5, 0.001).name('SLightZ')

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1))

    // Update effect composer
    effectComposer.setSize(sizes.width, sizes.height)
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 100)
camera.position.set(8, 0, 3)
camera.rotation.y = Math.PI * 0.8
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enableZoom = true
controls.minDistance = 10
controls.maxDistance = 10
controls.maxPolarAngle = Math.PI * 0.5
controls.rotateSpeed = 1

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFShadowMap

gui.add(renderer, 'toneMapping', {
    No: THREE.NoToneMapping,
    Linear: THREE.LinearToneMapping,
    Reinhard: THREE.ReinhardToneMapping,
    Cineon: THREE.CineonToneMapping,
    ACESFilmic: THREE.ACESFilmicToneMapping
})

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()
